<template>
  <div class="col">
    <el-table
      :data="paginatedData"
      stripe
      size="medium"
      style="table-layout: auto"
      v-loading="isLoading"
    >
      <el-table-column prop="name" :label="$t('Class_name')" min-width="120">
        <template slot-scope="scope">
          <router-link :to="`/groups/group/students/${scope.row.id}`" replace>
            {{ scope.row.name }}
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="course_name" :label="$t('Global.course_name')" min-width="120">
        <template slot-scope="scope">
          {{ getCourseTitle(scope.row.course_name) }}
        </template>
      </el-table-column>

      <el-table-column prop="days" :label="$t('Global.days')" min-width="100">
        <template slot-scope="scope">
          <span class="border-bottom border-secondary d-block text-right" v-for="day in scope.row.days" :key="day.id">
            {{ day }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="start_from" sortable :label="$t('Global.start_from')" width="130">
        <template slot-scope="scope">
          {{ scope.row.start_from ? toLocalDatetime(scope.row.start_from, "en") : "_" }}
        </template>
      </el-table-column>

      <el-table-column prop="end_at" :label="$t('Global.end_at')" width="130">
        <template slot-scope="scope">
          {{ scope.row.end_at ? toLocalDatetime(scope.row.end_at, "en") : "_" }}
        </template>
      </el-table-column>

      <el-table-column prop="age_group" :label="$t('Global.age_class')">
        <template slot-scope="scope">
          {{ getAgeGroup(scope.row.age_group) }}
        </template>
      </el-table-column>

      <el-table-column prop="promo_code" :label="$t('Global.promo_code')" min-width="90">
      </el-table-column>

      <el-table-column prop="pending_group_tasks_count" :label="$t('pending_classes')" min-width="80">
        <template slot-scope="scope">
          <el-badge :type="scope.row.pending_group_tasks_count > 0 ? 'warning' : 'primary'" :value="scope.row.pending_group_tasks_count">
          </el-badge>
        </template>
      </el-table-column>

      <el-table-column prop="status" :label="$t('Global.status')" min-width="120">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 0" round size="small" type="danger">
            {{ $t("non-active") }}
          </el-button>
          <el-button v-if="scope.row.status == 1" round size="small" type="primary">
            {{ $t("active") }}
          </el-button>
          <el-button v-if="scope.row.status == 3" round size="small" type="warning">
            {{ $t("hidden") }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column prop="" label="_" width="100" v-if="!hasRole('teacher')">
        <template slot-scope="scope">
          <div :class="{ 'd-none': hasRole('teacher') }">
            <!-- edit group  -->
            <div>
              <i class="fa fa-edit text-info mx-1" style="cursor: pointer" @click="openeditdialog(scope.row)" v-tooltip.top-center="$t('Edit_Group')"></i>
              <!-- activate & deactivate  -->
              <i v-if="scope.row.status == 1 || scope.row.status == 3" v-tooltip.top-center="$t('Delete_Group')" class="fa fa-trash text-danger mx-1" @click="deleteGroup(scope.row.id)"></i>
              <!-- hide & show  -->
              <i v-if="scope.row.status == 3" v-tooltip.top-center="$t('Show_Group')" class="fa fa-eye-slash text-danger mx-1" @click="changeGroupStatus({ group_id: scope.row.id, status: { status: 1 } })"></i>
              <i v-if="scope.row.status != 3" v-tooltip.top-center="$t('Hide_Group')" class="fa fa-eye text-primary mx-1" @click="changeGroupStatus({ group_id: scope.row.id, status: { status: 3 } })"></i>
            </div>

            <div>
              <router-link class="mx-1" :to="`/groups/group/score/${scope.row.id}`" replace>
              </router-link>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next"
      :total="count"
      style="margin-top: 20px"
    />

    <!-- Edit Group Dialog  -->
    <el-dialog
      :title="$t('Edit_Group')"
      width="70%"
      :visible.sync="editdialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        :model="editedGroup"
        ref="editedGroupRef"
        :rules="editedGroupRules"
        label-position="top"
        v-loading="$store.getters['groups/load']"
      >
        <el-row :gutter="20">
          <el-col :span="24" :md="12">
            <!-- ref="testBlur"
                @focus="handlerBlur" -->
            <el-form-item :label="$t('Global.days')" prop="days">
              <el-select
                v-model="editedGroup.days"
                clearable
                :placeholder="$t('Global.days')"
                style="width: 100%"
                multiple
              >
                <el-option
                  v-for="(item, index) in days"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Class_name')" prop="name">
              <el-input
                v-model="editedGroup.name"
                style="width: 100%"
                :placeholder="$t('Class_name')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <!-- ref="ageBlur"
                @focus="handlerBlurAge" -->
            <el-form-item :label="$t('Global.age_class')">
              <el-select
                v-model="editedGroup.age_group"
                clearable
                :placeholder="$t('Global.age_class')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('courses')" prop="course_id">
              <el-select
                v-model="editedGroup.course_id"
                :placeholder="$t('courses')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in $store.getters[
                    'user/getCoursesItems'
                  ]"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.start_from')" prop="start_from">
              <el-date-picker
                v-model="editedGroup.start_from"
                type="date"
                :placeholder="$t('Global.start_from')"
                :picker-options="datePickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.end_at')" prop="end_at">
              <el-date-picker
                v-model="editedGroup.end_at"
                type="date"
                :placeholder="$t('Global.end_at')"
                :picker-options="datePickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.grade')" prop="grade">
              <el-input
                :label="$t('Global.grade')"
                v-model="editedGroup.grade"
                class="w-100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.school')" prop="school">
              <el-input
                :label="$t('Global.school')"
                v-model="editedGroup.school"
                class="w-100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.country')" prop="country">
              <el-input
                :label="$t('Global.country')"
                v-model="editedGroup.country"
                class="w-100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('Global.city')" prop="city">
              <el-input
                :label="$t('Global.city')"
                v-model="editedGroup.city"
                class="w-100"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto" dir="ltr">
        <el-button class="text-center ml-lg-2 mx-auto d-block w-100 my-1" @click="editdialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1" type="primary" @click="editGroup">
          {{ $t("save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "student",
  components: { },
  data() {
    return {
      // edit data for edit dialog
      editedGroup: {},
      editdialog: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      days: [
        this.$t("sunday"),
        this.$t("monday"),
        this.$t("tuesday"),
        this.$t("wednesday"),
        this.$t("thursday"),
        this.$t("friday"),
        this.$t("saturday"),
      ],
      ageList: [
        { id: 1, title: this.$t("less_than_6") },
        { id: 2, title: this.$t("from_6_to_8") },
        { id: 3, title: this.$t("from_9_to_12") },
        { id: 4, title: this.$t("more_than_13") },
        { id: 5, title: this.$t("all_ages") },
      ],
      editedGroupRules: {
        name: [
          {
            required: true,
            message: this.$t("course_name_required"),
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: this.$t("days_required"),
            trigger: "change",
          },
        ],
        course_id: [
          {
            required: true,
            message: this.$t("course_required"),
            trigger: "change",
          },
        ],
        grade: [
          {
            required: true,
            message: this.$t("grade_required"),
            trigger: "blur",
          },
        ],
        school: [
          {
            required: true,
            message: this.$t("school_required"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: this.$t("country_required"),
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("city_required"),
            trigger: "blur",
          },
        ],
      },
      currentPage: 1,
      pageSize: 10,
      isLoading: true,
    };
  },
  watch: {
    groupsData(newVal) {
      if (newVal.length > 0) {
        this.isLoading = false;
      }
    },
  },
  computed: {
    groupsData() {
      return this.$store.getters["user/users"];
    },
    count() {
      return this.groupsData.length;
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.groupsData.slice(start, end);
    }
  },
  methods: {
    Refresh() {
      this.isLoading = true;
      // Remove pagination parameters to get all data at once
      this.$store.dispatch("user/groupsUsers", { query: { order_by: "id" } }).then((res) => {
        this.isLoading = false;
      });
    },

    Export(query) {
      this.$store.dispatch("user/export", { query: query });
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      // Remove API call as we're using client-side pagination
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      // Remove API call as we're using client-side pagination
    },

    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },
    // table methods
    getAgeGroup(id) {
      switch (id) {
        case 1:
          return this.$t("less_than_6");
        case 2:
          return this.$t("from_6_to_8");
        case 3:
          return this.$t("from_9_to_12");
        case 4:
          return this.$t("more_than_13");
        default:
          return this.$t("all_ages");
      }
    },

    openeditdialog(item) {
      // console.log(item);
      this.editedGroup = { ...item };
      this.editdialog = true;
      // console.log(item);
    },

    editGroup() {
      this.$refs["editedGroupRef"].validate((valid) => {
        if (valid) {
          // remove unused properties since it is need at the database and will cause error
          // delete this after validation or the form will not be valid
          delete this.editedGroup.course_name;
          delete this.editedGroup.course_image;
          delete this.editedGroup.users;
          delete this.editedGroup.pending_group_tasks_count;
          delete this.editedGroup.promo_code;
          delete this.editedGroup.status;
          delete this.editedGroup.teacher_id;
          this.$store
            .dispatch("groups/updateGroup", {
              data: this.editedGroup,
              id: this.editedGroup.id,
            })
            .then((_) => {
              this.Refresh();
              this.editdialog = false;
            });
        } else {
          console.log("erorr");
          return false;
        }
      });
    },

    changeGroupStatus(query) {
      Swal.fire({
        title:
          query.status.status == 3
            ? this.$t("confirm_hide_group")
            : this.$t("confirm_show_group"),
        text:
          query.status.status == 3
            ? this.$t("ask_hide_group")
            : this.$t("ask_show_group"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("user/changeGroupStatus", query).then((_) => {
            this.Refresh();
            Swal.fire({
              title: query.status.status
                ? this.$t("is_hidden")
                : this.$t("is_hidden"),
              text: quey.status.status
                ? this.$t("group_data_shown")
                : this.$t("group_data_shown"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    deleteGroup(id) {
      Swal.fire({
        title: this.$t("confirm_group_delete"),
        text: this.$t("ask_for_delete_group"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("groups/deleteGroup", id).then((_) => {
            this.Refresh();
            Swal.fire({
              title: this.$t("deleted"),
              text: this.$t("group_deleted"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // Reset pagination and refresh data when entering the route
      vm.currentPage = 1;
      vm.pageSize = 10;
      vm.Refresh();
    });
  },
  mounted() {
    if (this.groupsData.length > 0) {
      this.isLoading = false;
    } else {
      this.Refresh();
    }
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

::v-deep .el-radio {
  margin-left: 30px;
  margin-right: 0;
}

::v-deep .el-radio__input {
  margin-left: 10px;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}

::v-deep .activity-rate .el-icon-star-on:before {
  font-size: 3rem;
}

::v-deep .activity-rate .el-icon-star-off:before {
  font-size: 3rem;
}

::v-deep .el-rate__icon.fa-shield {
  font-size: 3rem;
  margin-right: 1.2rem;
}

::v-deep .el-rate__text {
  margin-right: 1rem;
  font-size: 2rem;
}
</style>

<style lang="scss">
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
  color: #777;
}
</style>
